jQuery(window).load(function(){

  // Since larger VC grids are created on window load, waiting a bit before
  // equalizing heights makes the result more reliable.
  function resize_vc_grid_elements() {
    jQuery(".vc_grid").each(function(){
      var maxHeight = 0;
      jQuery(this).find(".vc_grid-equalizer").each(function(){
       if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
      })
      .height(maxHeight);
    });
  }
  setTimeout(resize_vc_grid_elements,250);
  jQuery(window).resize(function(){
    jQuery(".vc_grid-equalizer").height('auto');
    resize_vc_grid_elements();
  });
});
